<template>
  <div class="tab-content" id="PTtab-6" :key="'PTtab-6'" >
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2">베팅내역</h3>
      </div>
      <div class="PTsch">
        <div class="datesearchPT">
          <date-filter :retail="true" @search="loadData(1)"
                       @update="onChangeDateTable"
                       :defaultDay="0"
                       :startDate="mainTableDate.startDate"
                       :endDate="mainTableDate.endDate"
                       :isOldYn="true"
                       :id="'main-date-checkbox1'"
                       @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
        </div>
        <div class="datesearchM">
           <date-filter-mobile :retail="true" @search="loadData(1)"
                              @update="onChangeDateTable"
                              :defaultDay="0"
                              :startDate="mainTableDate.startDate"
                              :endDate="mainTableDate.endDate"
                              :isOldYn="true"
                              :id="'main-date-checkbox1'"
                              @setOldYn="setOldYn" :oldYn="reqData.oldYn"
          />
        </div>
        <div class="searchPT">
          <select v-model="reqData.resultType">
            <option value="" selected>전체</option>
            <option value="win" selected>승</option>
            <option value="lose">패</option>
            <option value="draw">무</option>
            <option value="wait">대기</option>
            <option value="cancel">취소</option>
          </select>
          <input type="text" :placeholder="$t('front.member.emptyMemId')" v-model="reqData.searchMemId"/>
          <a @click="loadData(1)">
            <img src="@/assets/img/search.png" alt=""/>
          </a>
        </div>
        <div class="searchPT">
          <select v-model="reqData.gameCategory">
            <option value="casino" selected>카지노</option>
            <option value="slot" >슬롯</option>
          </select>
          <h5>게임 종류</h5>
          <select v-model="reqData.vendorCode">
            <option value="" selected>전체보기</option>
            <template v-for="(item, code) in commonCodeByOrder[reqData.gameCategory]" :key="code">
              <option :value="item.code">{{item.codeName}}</option>
            </template>
          </select>
        </div>
      </div>
      <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="7%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="11%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="11%">
                <col width="7%">
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>게임 종류</th>
                  <th>게임구분</th>
                  <th>베팅날짜</th>
                  <th>처리 전 게임머니</th>
                  <th>베팅금</th>
                  <th>당첨금</th>
                  <th>윈루즈</th>
                  <th>처리 후 게임머니</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="bettingList.length > 0">
                  <template v-for="item in bettingList" :key="item.betIdx">
                    <tr>
                      <td>{{item.betIdx}}</td>
                      <td>{{item.memId}}</td>
                      <td>{{item.vendorName}}</td>
                      <td>{{item.gameName}}</td>
                      <td>{{dateFormatAll(item.createdAt)}}</td>
                      <td>{{thousand(item.beforeCash)}}</td>
                      <td>{{thousand(item.cash)}}</td>
                      <td>{{thousand(item.winCash)}}</td>
                      <td>{{ thousand(Number(item.winCash) - Number(item.cash)) }}</td>
                      <td>{{thousand(item.afterCash)}}</td>
                      <td>{{typeName(item.type)}}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                  </tr>
                </template>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
               <template v-if="bettingList.length > 0">
                 <template v-for="item in bettingList" :key="item.betIdx">
                   <ul>
                     <li><em>번호</em>{{item.betIdx}}</li>
                     <li><em>아이디</em>{{item.memId}}</li>
                     <li><em>게임 종류</em>{{item.vendorName}}</li>
                     <li><em>게임구분</em>{{item.gameName}}</li>
                     <li><em>베팅날짜</em>{{dateFormatAll(item.createdAt)}}</li>
                     <li><em>처리 전<br />게임머니</em>{{thousand(item.beforeCash)}}</li>
                     <li><em>베팅금</em>{{thousand(item.cash)}}</li>
                     <li><em>당첨금</em>{{thousand(item.winCash)}}</li>
                     <li><em>윈루즈</em>{{ thousand(Number(item.winCash) - Number(item.cash)) }}</li>
                     <li><em>처리 후<br />게임머니</em>{{thousand(item.afterCash)}}</li>
                     <li><em>상태</em>{{typeName(item.type)}}</li>
                   </ul>
                 </template>
               </template>
               <template v-else>
                 <ul>
                    <li>내역 없음</li>
                 </ul>
               </template>
            </div>
         </div>
      </div>
      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="loadData"
      />
    </div>
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { getBottomBettingList, partnerLevels } from '@/api/retail'
export default {
  name: 'PartnerBetting',
  components: { DateFilter, DateFilterMobile, Pagination },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        gameCategory: 'casino',
        vendorCode: '',
        resultType: '',
        searchMemId: '',
        chkTodayYn: 'N',
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N'
      },
      bettingList: []
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))
    await this.loadData()
    this.emitter.emit('Loading', false)
  },
  methods: {
    typeName (type) {
      const TYPE_TEXT = {
        win: '승',
        lose: '패',
        draw: '무',
        cancel: '취소',
        wait: '대기'
      }
      return TYPE_TEXT[type]
    },
    thousand,
    setOldYn (data) {
      console.log(data)
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadData (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      this.emitter.emit('Loading', true)
      const params = { ...this.reqData, page: page }
      await getBottomBettingList(params).then(res => {
        const data = res.data.data
        if (data) {
          this.bettingList = data.list

          if (data.pageInfo) {
            this.pageInfo = data.pageInfo
          }
        }

        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>
<style scoped>
.PTsch .searchPT h5{line-height: 30px;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped src="@/styles/striNew.css"></style>
